import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const WorkPage = () => (
  <Layout>
    <SEO title="Work" />
    <div className="work-wrapper">
      <div className="wrapper">
        <div className="">
          <h2>Work</h2>
          <h3><a href="https://sexier.software">Sexier Software</a></h3>
          <p>boutique & bespoke software</p>
          <h3><a href="https://misfitmansion.com">Misfit Mansion</a></h3>
          <p>nomad friendly residence</p>
          <h3><a href="https://talkshirty2.me">TalkShirty2.Me</a></h3>
          <p>niche philathropic apparel</p>
          <h3><a href="https://curedcrafts.com">Cured Crafts</a></h3>
          <p>resin creation marketplace</p>
          <h3><a href="https://buildthis.biz">BuildThis</a></h3>
          <p>DAO incubation platform</p>
          <br/>
        </div>
      </div>
    </div>
  </Layout>
)

export default WorkPage
